import { Table, TableRow, TableCell, TableBody, LinearProgress, IconButton, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import EditIcon from '@mui/icons-material/Edit';
import TaskAltRoundedIcon from '@mui/icons-material/TaskAltRounded';
import { useNavigate } from 'react-router-dom';

const Subject = {
    1: 'Reading',
    2: 'Math'
};

export default function AssessmentsList({ assessments, completed, loading, term }) {
    const theme = useTheme();
    const navigate = useNavigate();

    const onAssessmentClick = (studentId, studentName, subject) => {
        navigate('/assessment', { state: { studentId, studentName, subject, term } });
    };

    return (
        <div style={{ border: 'solid 1px #e7e7e7', borderRadius: '10px' }}>
            <div style={{ padding: '22px' }}>
                <span style={{ fontSize: 17, fontWeight: '500', color: 'black' }}>{completed ? 'Completed' : 'Required'}</span>
            </div>
            <div style={{ backgroundColor: '#e7e7e7', height: '1px' }} />
            {loading ? (
                <LinearProgress />
            ) : (
                <div style={{ padding: '2% 5%' }}>
                    <Table>
                        <TableBody>
                            {assessments.map((a, i) => (
                                <TableRow key={i} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                    <TableCell>
                                        <Typography fontWeight={450} fontSize={14} color="black">
                                            {a.studentName}
                                        </Typography>
                                        <Typography fontSize={12} sx={{ paddingTop: 0.4, color: theme.palette.grey[500] }}>
                                            {Subject[a.subject]}
                                        </Typography>
                                    </TableCell>
                                    <TableCell align="right">
                                        {a.completed ? (
                                            <div style={{ padding: 5 }}>
                                                <TaskAltRoundedIcon sx={{ fontSize: '30px', color: theme.palette.success.dark }} />
                                            </div>
                                        ) : (
                                            <IconButton onClick={() => onAssessmentClick(a.studentId, a.studentName, a.subject)}>
                                                <EditIcon sx={{ fontSize: '30px' }} color="primary" />
                                            </IconButton>
                                        )}
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                    {!assessments.length && (
                        <Typography align="center">{completed ? 'No Completed Assessments' : 'No Required Assessments'}</Typography>
                    )}
                </div>
            )}
        </div>
    );
}
