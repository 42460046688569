import { TextField, InputAdornment } from '@mui/material';
import LockTwoToneIcon from '@mui/icons-material/LockTwoTone';

export default function LockedTextField({ label, value, multilineRows }) {
    return (
        <TextField
            label={label}
            value={value}
            disabled
            fullWidth
            multiline={multilineRows > 1}
            minRows={multilineRows}
            InputProps={{
                endAdornment: (
                    <InputAdornment position="end">
                        <LockTwoToneIcon fontSize="small" />
                    </InputAdornment>
                ),
                style: { paddingRight: 14 }
            }}
        />
    );
}
