import { useNavigate, useLocation } from 'react-router-dom';

import { Typography, Box, Tooltip, IconButton } from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';

import MainCard from 'ui-component/cards/MainCard';

export default function CoachingAgenda() {
    const navigate = useNavigate();
    const { state } = useLocation();

    const onCancelClick = () => navigate(state?.callbackUrl ?? '/calendar');

    return (
        <MainCard sx={{ position: 'relative' }}>
            <Box sx={{ position: 'absolute', top: 10, right: 10 }}>
                <Tooltip title="Close">
                    <IconButton onClick={onCancelClick}>
                        <CancelIcon />
                    </IconButton>
                </Tooltip>
            </Box>
            <Typography variant="h4" align="center">
                Coaching Agenda
            </Typography>
        </MainCard>
    );
}
