import axios from 'utils/axios';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { Backdrop, CircularProgress } from '@mui/material';

import { useDispatch } from 'store';
import { showErrorAlert } from 'utils/global-functions';

import CoachingLog from './CoachingLog';
import StandardSession from './StandardSession';

export default function Session() {
    const [session, setSession] = useState(null);
    const [loadingSession, setLoadingSession] = useState(undefined);

    const { state } = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const closeSession = () => navigate(state?.callbackUrl ? state.callbackUrl : '/calendar');

    useEffect(() => {
        async function getSession() {
            setLoadingSession(true);

            try {
                const { data } = await axios.get(`/Session/${state.session.id}`);
                console.log(data);
                setSession(data);
            } catch (err) {
                showErrorAlert(dispatch, err);
                setLoadingSession(false);
                closeSession();
            }
        }

        getSession();
    }, []);

    if (session) {
        return session?.sessionType === 1 ? (
            <CoachingLog session={session} closeSession={closeSession} setLoadingSession={setLoadingSession} />
        ) : (
            <StandardSession
                session={session}
                setSession={setSession}
                closeSession={closeSession}
                loadingSession={loadingSession}
                setLoadingSession={setLoadingSession}
            />
        );
    }

    return (
        <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open>
            <CircularProgress color="primary" />
        </Backdrop>
    );
}
