import { lazy } from 'react';

// project imports
import AuthGuard from 'utils/route-guard/AuthGuard';
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
import CardListPage from 'views/contact/List';
import SupervisorReview from 'views/supervisor-review';
import Session from 'views/calendar/session';
import CoachingAgenda from 'views/calendar/CoachingAgenda';
import AssessmentsPage from 'views/asessments';
import Assessment from 'views/asessments/Assessment';

// sample page routing
// const HomePage = Loadable(lazy(() => import('views/home')));
const AttendancePage = Loadable(lazy(() => import('views/attendance')));
const CalendarPage = Loadable(lazy(() => import('views/calendar')));
const AuthResetPassword = Loadable(lazy(() => import('views/pages/authentication/ResetPassword3')));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
    path: '/',
    element: (
        <AuthGuard>
            <MainLayout />
        </AuthGuard>
    ),
    children: [
        {
            path: '/',
            element: <AttendancePage />
        },
        {
            path: '/calendar',
            element: <CalendarPage />
        },
        {
            path: '/session/:sessionId',
            element: <Session />
        },
        localStorage.roles?.includes('Title1Supervisor') || localStorage.roles?.includes('Admin')
            ? {
                  path: '/coachingagenda',
                  element: <CoachingAgenda />
              }
            : null,
        {
            path: '/attendance',
            element: <AttendancePage />
        },
        {
            path: '/lesson-library',
            element: <CardListPage />
        },
        {
            path: '/assessments',
            element: <AssessmentsPage />
        },
        {
            path: '/assessment',
            element: <Assessment />
        },
        localStorage.getItem('isSupervisor') === 'true'
            ? {
                  path: '/supervisor-review',
                  element: <SupervisorReview />
              }
            : null,
        {
            path: '/reset-password',
            element: <AuthResetPassword />
        }
    ].filter(Boolean)
};

export default MainRoutes;
