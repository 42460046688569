import axios from 'utils/axios';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import {
    Autocomplete,
    FormControl,
    FormLabel,
    RadioGroup,
    Radio,
    FormControlLabel,
    TextField,
    Typography,
    Grid,
    OutlinedInput,
    InputLabel,
    Divider,
    createFilterOptions,
    Button,
    ButtonGroup
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import skillLevels from './skillLevels';
import { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

const TutoringSession = ({ session, disabled, disableFields, isSubmittingSession, setIsSubmittingSession }) => {
    const { state } = useLocation();
    const filter = createFilterOptions();
    const navigate = useNavigate();
    const tutoringSession = {
        ...session,
        students: session.students.map((x) => ({ ...x }))
    };
    console.log(tutoringSession);
    const { score } = { ...tutoringSession.students[0] };
    const [submitClicked, setSubmitClicked] = useState(false);
    const [sessionInfo, setSessionInfo] = useState({
        students: tutoringSession.students.map((s) => ({
            classParticipantId: s.classParticipantId,
            studentId: s.studentId,
            studentName: s.studentName,
            attendance: s.attendance?.attendance,
            attendanceId: s.attendance?.attendanceId,
            score: s.score?.scoreValue ?? null,
            scoreId: s.score?.scoreId
        })),
        skillLevel: score ? { title: score.skillLevel } : null,
        sessionNotes: score?.scoreNote ?? '',
        followUpSession: score?.followUpSession ?? '',
        subject: tutoringSession.subject
    });

    const handleStudentChange = (studentId, key, value) => {
        const updatedStudents = structuredClone(sessionInfo.students);
        const studentIndex = updatedStudents.findIndex((s) => s.studentId === studentId);
        updatedStudents[studentIndex][key] = value;

        if (key === 'attendance' && value !== 2) {
            updatedStudents[studentIndex].score = null;
        }

        const updatedSessionInfo = { ...sessionInfo, students: updatedStudents };
        if (updatedStudents.every((s) => s.attendance !== 2)) {
            updatedSessionInfo.skillLevel = null;
            updatedSessionInfo.sessionNotes = '';
            updatedSessionInfo.followUpSession = '';
        }
        setSessionInfo(updatedSessionInfo);
    };

    const handleSubmitSession = async () => {
        try {
            setIsSubmittingSession(true);
            setSubmitClicked(true);
            console.log('score');
            console.log(sessionInfo.score === null);
            console.log('skilllevel ');
            console.log(sessionInfo.skillLevel);

            if (sessionInfo.students.some((s) => s.attendance === 2)) {
                const studentsMissingScore = sessionInfo.students.some((s) => s.attendance === 2 && s.score === null);
                if (studentsMissingScore || !sessionInfo.skillLevel || !sessionInfo.sessionNotes || !sessionInfo.followUpSession) {
                    return;
                }
            }

            await axios.post('/Session/SubmitTutoringSession', {
                sessionId: tutoringSession.id,
                sessionParticipants: sessionInfo.students.map((s) => ({
                    classParticipantId: s.classParticipantId,
                    attendance: s.attendance,
                    attendanceId: s.attendanceId,
                    score: s.score,
                    scoreId: s.scoreId
                })),
                skillLevel: sessionInfo.skillLevel?.title ? sessionInfo.skillLevel.title : '',
                sessionNotes: sessionInfo.sessionNotes ? sessionInfo.sessionNotes : '',
                followUpSession: sessionInfo.followUpSession ? sessionInfo.followUpSession : ''
            });
            navigate(state.callbackUrl ? state.callbackUrl : '/calendar');
        } catch (err) {
            console.error(err);
        } finally {
            setIsSubmittingSession(false);
        }
    };

    const handleChange = (key, value) => {
        setSessionInfo({ ...sessionInfo, [key]: value });
    };

    const getAcademicSkillOptions = () => {
        switch (sessionInfo.subject) {
            case 'Reading':
                return skillLevels.reading;
            case 'Math':
                return skillLevels.math;
            default:
                return [];
        }
    };

    const getScoreStyle = (score) => (score === null && submitClicked ? { color: 'red' } : {});

    return (
        <Grid container>
            <Grid item xs={1.3}>
                <Typography variant="h5" sx={{ mb: 2, ml: 1, mt: 2 }}>
                    Students
                </Typography>
                {sessionInfo.students.map((s) => (
                    <div key={s.studentId}>
                        <FormControl sx={{ mt: 1.6, mb: 3.6, ml: 1 }}>
                            <Typography>{s.studentName}</Typography>
                        </FormControl>
                    </div>
                ))}
            </Grid>
            <Grid item xs={2.7} sx={{ mt: 6.4 }}>
                {sessionInfo.students.map((s) => (
                    <div key={s.studentId}>
                        <ButtonGroup size="small" variant="text" sx={{ mt: 0.7, mr: 3.5 }} disabled={disabled || disableFields}>
                            <Button
                                variant={s.attendance === 2 ? 'contained' : 'text'}
                                onClick={() => {
                                    if (s.attendance !== 2) {
                                        handleStudentChange(s.studentId, 'attendance', 2);
                                    }
                                }}
                            >
                                P
                            </Button>
                            <Button
                                variant={s.attendance === 1 ? 'outlined' : 'text'}
                                onClick={() => {
                                    if (s.attendance !== 1) {
                                        handleStudentChange(s.studentId, 'attendance', 1);
                                    }
                                }}
                            >
                                A
                            </Button>
                        </ButtonGroup>
                        <FormControl
                            key={s.studentId}
                            disabled={disabled || s.attendance !== 2}
                            component="fieldset"
                            variant="standard"
                            error={s.score === null && submitClicked}
                        >
                            <RadioGroup
                                row
                                name="grade"
                                value={s.score}
                                onChange={(e) => handleStudentChange(s.studentId, 'score', parseInt(e.target.value, 10))}
                            >
                                <FormControlLabel
                                    value={0}
                                    control={<Radio sx={getScoreStyle(s.score)} />}
                                    label="Pass"
                                    sx={getScoreStyle(s.score)}
                                />
                                <FormControlLabel
                                    value={1}
                                    control={<Radio sx={getScoreStyle(s.score)} />}
                                    label="Fail"
                                    sx={getScoreStyle(s.score)}
                                />
                            </RadioGroup>
                        </FormControl>
                    </div>
                ))}
            </Grid>

            {sessionInfo.students.some((s) => s.attendance === 2) && (
                <>
                    <Divider orientation="vertical" flexItem sx={{ mr: '-1px' }} />
                    <Grid item xs={8} md={8}>
                        <Typography variant="h5" sx={{ ml: 3, mt: 2 }}>
                            Session Data
                        </Typography>
                        <Grid container spacing={3} sx={{ p: 3 }}>
                            <Grid item xs={12}>
                                <FormControl disabled={disabled} fullWidth>
                                    <Autocomplete
                                        disabled={disabled}
                                        fullWidth
                                        value={sessionInfo.skillLevel}
                                        onChange={(event, newValue) => {
                                            const setValue = (newValue) => {
                                                setSessionInfo({ ...sessionInfo, skillLevel: newValue });
                                            };

                                            if (typeof newValue === 'string') {
                                                setValue({
                                                    title: newValue
                                                });
                                            } else if (newValue && newValue.inputValue) {
                                                // Create a new value from the user input
                                                setValue({
                                                    title: newValue.inputValue
                                                });
                                            } else {
                                                setValue(newValue);
                                            }
                                        }}
                                        filterOptions={(options, params) => {
                                            const filtered = filter(options, params);

                                            const { inputValue } = params;
                                            // Suggest the creation of a new value
                                            const isExisting = options.some((option) => inputValue === option.title);
                                            if (inputValue !== '' && !isExisting) {
                                                filtered.push({
                                                    inputValue,
                                                    title: `Custom Academic Skill: "${inputValue}"`
                                                });
                                            }

                                            return filtered;
                                        }}
                                        selectOnFocus
                                        clearOnBlur
                                        handleHomeEndKeys
                                        id="skill-level"
                                        options={getAcademicSkillOptions()}
                                        getOptionLabel={(option) => {
                                            // Value selected with enter, right from the input
                                            if (typeof option === 'string') {
                                                return option;
                                            }
                                            // Add "xxx" option created dynamically
                                            if (option.inputValue) {
                                                return option.inputValue;
                                            }
                                            // Regular option
                                            return option.title;
                                        }}
                                        renderOption={(props, option) => <li {...props}>{option.title}</li>}
                                        freeSolo
                                        renderInput={(params) => (
                                            <TextField
                                                fullWidth
                                                {...params}
                                                label="Academic Skill"
                                                error={!sessionInfo.skillLevel && submitClicked}
                                            />
                                        )}
                                    />
                                </FormControl>
                            </Grid>

                            <Grid item xs={12}>
                                <FormControl disabled={disabled} fullWidth error={!sessionInfo.sessionNotes && submitClicked}>
                                    <InputLabel>Session Notes</InputLabel>
                                    <OutlinedInput
                                        value={sessionInfo.sessionNotes}
                                        onChange={(e) => handleChange('sessionNotes', e.target.value)}
                                        type="text"
                                        label="Session Notes"
                                        id="notes"
                                        name="notes"
                                        multiline
                                        rows={5}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12}>
                                <FormControl disabled={disabled} fullWidth error={!sessionInfo.followUpSession && submitClicked}>
                                    <InputLabel>Follow up session</InputLabel>
                                    <OutlinedInput
                                        value={sessionInfo.followUpSession}
                                        onChange={(e) => handleChange('followUpSession', e.target.value)}
                                        type="text"
                                        label="Follow up session"
                                        id="followUpSession"
                                        name="followUpSession"
                                        multiline
                                        rows={5}
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>
                    </Grid>
                </>
            )}
            <Grid item textAlign="center" xs={12} sx={{ mt: 2 }}>
                <LoadingButton
                    disabled={disabled || sessionInfo.students.some((s) => !s.attendance)}
                    variant="contained"
                    loading={isSubmittingSession}
                    loadingPosition="start"
                    startIcon={<DoneAllIcon />}
                    onClick={() => handleSubmitSession()}
                >
                    Submit Session
                </LoadingButton>
            </Grid>
        </Grid>
    );
};

export default TutoringSession;
