import { TextField } from '@mui/material';
import LockedTextField from './LockedTextField';

export default function LockedOrUnlockedTextField({ label, value, multilineRows, locked = true, unlockedProps }) {
    return locked ? (
        <LockedTextField label={label} value={value} multilineRows={multilineRows} />
    ) : (
        <TextField label={label} value={value} fullWidth multiline={multilineRows > 1} minRows={multilineRows} {...unlockedProps} />
    );
}
