import { useNavigate, useLocation } from 'react-router-dom';
import { useState, useEffect } from 'react';
import axios from 'utils/axios';

// MUI imports
import {
    Box,
    Tooltip,
    IconButton,
    Grid,
    Backdrop,
    CircularProgress,
    Button,
    Dialog,
    DialogTitle,
    DialogActions,
    DialogContent
} from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';

// Project imports
import { useDispatch } from 'store';
import MainCard from 'ui-component/cards/MainCard';
import AssessmentQuestionsList from './AssessmentQuestionsList';
import { showErrorAlert } from 'utils/global-functions';

export default function Assessment() {
    const { state } = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [loading, setLoading] = useState(true);
    const [assessmentId, setAssessmentId] = useState('');
    const [assessmentQuestions, setAssessmentQuestions] = useState([]);
    const [student, setStudent] = useState({});
    const [assessmentTerm, setAssessmentTerm] = useState({});
    const [saving, setSaving] = useState(false);
    const [dialogOpen, setDialogOpen] = useState(false);

    const closeAssessment = () => navigate('/assessments');

    useEffect(() => {
        async function getAssessment() {
            const { studentId, studentName, subject, term } = state;
            setStudent({ id: studentId, name: studentName });
            setAssessmentTerm({ term });

            if (!studentId || !subject) {
                showErrorAlert(dispatch, 'Student ID or Subject not provided');
                closeAssessment();
                return;
            }

            try {
                const { data } = await axios.get(`/assessments/assessment?studentId=${studentId}&subject=${subject}`);
                setAssessmentId(data.id);
                setAssessmentQuestions(data.questions);
                setAssessmentTerm({ id: data.termId, term: data.term });
            } catch (ex) {
                showErrorAlert(dispatch, ex);
                closeAssessment();
            }

            setLoading(false);
        }

        getAssessment();
    }, []);

    const setQuestionScore = (questionId, score) => {
        setAssessmentQuestions(
            assessmentQuestions.map((q) => {
                if (q.id === questionId) {
                    q.score = score;
                }
                return q;
            })
        );
    };

    const onCloseClick = () => {
        if (assessmentQuestions.some((q) => q.score !== null && q.score !== undefined)) {
            setDialogOpen(true);
        } else {
            closeAssessment();
        }
    };

    const onSubmitClick = async () => {
        setSaving(true);

        try {
            await axios.post('/assessments/save', {
                studentId: student.id,
                assessmentId,
                assessmentTermId: assessmentTerm.id,
                scores: assessmentQuestions.map((q) => ({ assessmentQuestionId: q.id, score: q.score }))
            });

            closeAssessment();
            return;
        } catch (ex) {
            showErrorAlert(dispatch, ex);
        }

        setSaving(false);
    };

    const getSplitAssessmentQuestions = () => {
        const halfArrayIndex = Math.ceil(assessmentQuestions.length / 2);
        return [assessmentQuestions.slice(0, halfArrayIndex), assessmentQuestions.slice(halfArrayIndex)];
    };

    return (
        <MainCard title={`${student.name} - Term ${assessmentTerm.term} Assessment`} sx={{ position: 'relative' }}>
            <Box sx={{ position: 'absolute', top: 15, right: 15 }}>
                <Tooltip title="Close">
                    <IconButton onClick={onCloseClick}>
                        <CancelIcon />
                    </IconButton>
                </Tooltip>
            </Box>
            {loading ? (
                <Backdrop sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }} open>
                    <CircularProgress color="primary" />
                </Backdrop>
            ) : (
                <Grid container paddingRight={2} paddingLeft={2}>
                    {getSplitAssessmentQuestions().map((questionsArray, i) => (
                        <Grid item xs={6} padding={3} key={i}>
                            <AssessmentQuestionsList questionsArray={questionsArray} setQuestionScore={setQuestionScore} />
                        </Grid>
                    ))}
                    <Grid item xs={12} marginTop={3} display="flex" justifyContent="center">
                        <Button
                            variant="contained"
                            disabled={saving || assessmentQuestions.some((q) => q.score === null || q.score === undefined)}
                            sx={{ width: '12%' }}
                            onClick={onSubmitClick}
                        >
                            Submit
                            {saving && (
                                <CircularProgress
                                    size={22}
                                    sx={{
                                        position: 'absolute',
                                        top: '50%',
                                        left: '50%',
                                        marginTop: '-12px',
                                        marginLeft: '-12px'
                                    }}
                                />
                            )}
                        </Button>
                    </Grid>
                </Grid>
            )}
            <Dialog open={dialogOpen}>
                <DialogTitle>Close Assessment</DialogTitle>
                <DialogContent>Are you sure you want to close this page? Your changes will not be saved.</DialogContent>
                <DialogActions>
                    <Button onClick={closeAssessment}>YES</Button>
                    <Button onClick={() => setDialogOpen(false)}>NO</Button>
                </DialogActions>
            </Dialog>
        </MainCard>
    );
}
