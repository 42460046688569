import { useEffect, useState } from 'react';
import { List, ListItem, ListItemText, ToggleButtonGroup, ToggleButton } from '@mui/material';

export default function AssessmentQuestionsList({ questionsArray, setQuestionScore }) {
    return (
        <List>
            {questionsArray.map((q, i) => (
                <ListItem sx={{ padding: 0 }} key={i}>
                    <ListItemText primary={q.sequenceNumber} primaryTypographyProps={{ variant: 'h4' }} sx={{ minWidth: '17px' }} />
                    <ToggleButtonGroup
                        color="primary"
                        exclusive
                        fullWidth
                        sx={{ marginLeft: 2.5, marginRight: 3, marginBottom: 1, marginTop: 1 }}
                        size="small"
                        value={q.score}
                        onChange={(e, selected) => setQuestionScore(q.id, selected)}
                    >
                        <ToggleButton value={0}>Correct</ToggleButton>
                        <ToggleButton value={1} sx={{ padding: '4px!important' }}>
                            Partially Correct
                        </ToggleButton>
                        <ToggleButton value={2}>Incorrect</ToggleButton>
                    </ToggleButtonGroup>
                </ListItem>
            ))}
        </List>
    );
}
