import { Snackbar, Alert } from '@mui/material';

export default function ErrorSnackbarAlert({ message, setMessage }) {
    return (
        <Snackbar open autoHideDuration={6000} anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} onClose={() => setMessage('')}>
            <Alert severity="error" variant="filled">
                {message}
            </Alert>
        </Snackbar>
    );
}
