import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

// material-ui
import DoneAllIcon from '@mui/icons-material/DoneAll';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Button from '@mui/material/Button';
import FormControlLabel from '@mui/material/FormControlLabel';

import { Divider, Grid, Tooltip } from '@mui/material';
import { LoadingButton } from '@mui/lab';

// third-party

// project imports

// ==============================|| CALENDAR EVENT ADD / EDIT / DELETE ||============================== //

const TakeAttendanceForm = ({
    event,
    onSubmit,
    isFutureSession,
    isConfirmedSession,
    disableFields,
    isSavingAttendance,
    setIsSavingAttendance
}) => {
    const [students, setStudents] = useState([]);
    const [attendanceChanged, setAttendanceChanged] = useState(false);
    const [markAllPresent, setMarkAllPresent] = useState(false);
    const [disabledSession, setDisabledSession] = useState(false);

    const check = (id, value) => {
        console.log(value);
        const studentsCopy = students.map((s) => (s.studentId === id ? { ...s, attendance: value } : s));
        setStudents(studentsCopy);
        setAttendanceChanged(true);
    };

    useEffect(() => {
        if (event.students) {
            const studentsCopy = event.students.map((s) => ({
                studentId: s.studentId,
                studentName: s.studentName,
                studentFirstName: s.studentFirstName,
                studentMiddleName: s.studentMiddleName ? s.studentMiddleName : '',
                studentLastName: s.studentLastName,
                classParticipantId: s.classParticipantId,
                attendance: s.attendance ? s.attendance.attendance : 0,
                attendanceId: s.attendance ? s.attendance.attendanceId : ''
            }));
            setStudents(studentsCopy);
        }
        if (isFutureSession || isConfirmedSession || disableFields) {
            setDisabledSession(true);
        }
    }, []);

    const handleMarkAll = (value) => {
        setMarkAllPresent(value);
        const studentsCopy = students.map((s) => ({ ...s, attendance: value ? 2 : 0 }));
        setStudents(studentsCopy);
        setAttendanceChanged(true);
    };

    const handleSave = async () => {
        setIsSavingAttendance(true);
        await onSubmit(students);
        setIsSavingAttendance(false);
    };

    return students.length === 0 ? (
        <p>There are no students in this class</p>
    ) : (
        <div>
            <Grid>
                <FormControlLabel
                    sx={{ ml: 2, float: 'right' }}
                    label="Mark all present"
                    control={<Checkbox checked={markAllPresent} onChange={(e) => handleMarkAll(e.target.checked)} />}
                    disabled={disabledSession}
                />
                <TableContainer component={Paper}>
                    <Table aria-label="simple table" size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell align="left">Student</TableCell>
                                <TableCell align="center">Present</TableCell>
                                <TableCell align="center">Absent</TableCell>
                                <Tooltip placement="bottom" title="Student in school but unavailable to attend session">
                                    <TableCell align="center">N/A</TableCell>
                                </Tooltip>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {students.map((student) => (
                                <TableRow key={student.studentName} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                    <TableCell key={0} align="left" component="th" scope="row">
                                        {student.studentLastName}, {student.studentFirstName} {student.studentMiddleName}
                                    </TableCell>
                                    {Array.from([2, 1, 3]).map((v) => (
                                        <TableCell key={v} align="center">
                                            <Checkbox
                                                checked={student.attendance === v}
                                                onChange={() => check(student.studentId, v)}
                                                disabled={disabledSession}
                                            />
                                        </TableCell>
                                    ))}
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
            <Divider style={{ marginTop: '30px', width: '100%' }} />
            <Box textAlign="center" sx={{ mt: 2 }}>
                <LoadingButton
                    loading={isSavingAttendance}
                    loadingPosition="start"
                    variant="contained"
                    startIcon={<DoneAllIcon />}
                    onClick={handleSave}
                    disabled={(event.attendanceTaken && !attendanceChanged) || disabledSession}
                >
                    Submit
                </LoadingButton>
            </Box>
        </div>
    );
};

// AddEventFrom.propTypes = {
//     event: PropTypes.object,
//     range: PropTypes.object,
//     handleDelete: PropTypes.func,
//     handleCreate: PropTypes.func,
//     handleUpdate: PropTypes.func,
//     onCancel: PropTypes.func
// };

export default TakeAttendanceForm;
