import { openSnackbar } from 'store/slices/snackbar';

export const parseErrorResponse = (error) => {
    if (typeof error === 'string') return error;
    if (error && error.errors) {
        const errors = error.errors;
        return Object.entries(errors)
            .map(([key, value]) => `${value.join(', ')}`)
            .join(', ');
    }
    return error.message || 'An unknown error occurred.';
};

export const showErrorAlert = (dispatch, err) => {
    dispatch(
        openSnackbar({
            open: true,
            message: parseErrorResponse(err),
            variant: 'alert',
            alert: {
                color: 'error'
            },
            close: false
        })
    );
};
